<template>
  <div>
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-input
          class="select-width id-code-width"
          size="medium"
          v-model="queryForm.eventName"
          placeholder="事件名称" />
          
        <el-date-picker
          v-model="queryForm.pickerTime"
          size="medium"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期" />
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryUserInfo">查询</el-button>
        <el-button type="primary" size="small" @click="addUserInfo">新增</el-button>
      </div>
    </div>

    <TableModel
      :is-show-select="true"
      :is-show-tabs="false"
      :table-data="tableData"
      table-size="medium">
      <el-table-column label="操作" width="180" align="center">
        <template slot-scope="{ row }">
          <el-link type="primary" @click="editTableFun(row)">编辑</el-link>
          <el-link v-if="row.eventStatus == 1" type="primary" @click="deleteTableFun(row)">重启</el-link>
          <el-link v-else type="danger"  @click="deleteTableFun(row)">结束</el-link>
          <el-link type="danger"  @click="deleteTable(row)">删除</el-link>
        </template>
      </el-table-column>
      <el-table-column prop="eventName" label="事件名称"></el-table-column>
      <el-table-column prop="eventRemark" label="事件描述" />
      <el-table-column prop="startTime" label="开始时间" />
      <el-table-column prop="endTime" label="结束时间" />
      <el-table-column prop="eventStatus" label="状态">
        <template slot-scope="{ row }">
          <el-tag size="small" v-if="row.eventStatus == 0">进行中</el-tag>
          <el-tag size="small" type="danger" v-else-if="row.eventStatus == 1">结束</el-tag>
        </template>
      </el-table-column>
    </TableModel>

    <div class="content-page">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageObj.pageNumber"
        :page-size="pageObj.pageSize"
        :total="pageObj.total"
        :page-sizes="[10, 20, 30, 50, 100]"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>

    <el-dialog title="编辑医疗机构信息" :visible.sync="dialogFormVisible" :close-on-click-modal="false" v-loading="loading">
      <el-form :model="form" ref="addForm">
        <el-form-item label="事件名称:" label-width="120px" prop="eventName" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-input v-model="form.eventName"  maxlength="50" show-word-limit ></el-input>
        </el-form-item>  
        <el-form-item label="事件描述:" label-width="120px" prop="eventRemark" :rules="{ required: false, message: '不能为空', trigger: 'blur'}">
          <el-input type="textarea" :autosize="{ minRows: 5}" maxlength="100" show-word-limit v-model="form.eventRemark"></el-input>
        </el-form-item>
        <el-form-item label="涉事场所:" label-width="120px" prop="placeInvolvedList" :rules="{ required: true, message: '不能为空', trigger: 'blur'}">
          <el-tag
            :key="index"
            v-for="(tag,index) in form.placeInvolvedList"
            :closable="!tag.id"
            :disable-transitions="false"
            @close="handleClose(tag)">
            {{tag.placeName}}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="inputValue.placeName"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 添加场所</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogSubmit('addForm')">确 定</el-button>
      </div>
    </el-dialog>



  </div>
</template>

<script>
import TableModel from '../../components/TableModel';
import {subTime} from '@/filters';
import { eventList,eventSave,eventUpdate,findEvent,eventDelete  } from '../../api/SystemManagement/index.js';
const formObj ={//表单
        eventName:"",
        eventRemark:"",
        eventStatus: "0",
        startTime:"",
        endTime:"",
        placeInvolvedList:[]
      };
export default {
  name: "OrganizationalManage",
  components: { TableModel },
  data() {
    return {
      queryForm: {
        eventName:'',
        pickerTime:[]
      },
      tableData: [], //表单数据
      pageObj:{
        pageNumber: 1,
        pageSize: 20,
        total: 0,
      },
      dialogFormVisible:false,
      dialogFormVisibleType:'add',
      form: formObj,//表单

      //场所标签
      dynamicTags: [{placeName:'标签一',id:''},{placeName:'标签2',id:'123'}],
      inputVisible: false,
      inputValue: {placeName:'',id:''},
      loading:false
    }
  },

  computed: {

  },

  created() {
    this.queryUserInfo();
  },

  methods: {
    //请求场所列表
    getCSList(row){
      let params = {id:row.id};
      findEvent({params}).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          this.form = Object.assign({},data.data.eventDTO,{placeInvolvedList:data.data.placeInvolvedList || []});
        }
      })
    },
    //场所标签操作
    handleClose(tag) {
      this.form.placeInvolvedList.splice(this.form.placeInvolvedList.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue.placeName != '') {
        this.form.placeInvolvedList.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = {placeName:'',id:''};
    },


    //请求列表
    getUserInfo(){
      let params = Object.assign({},this.queryForm,this.pageObj);
      console.log(111,params);
      if (params.pickerTime && params.pickerTime.length == 2) {
        params.startTime = params.pickerTime[0] + " 00:00:00";
        params.endTime = params.pickerTime[1] + " 23:59:59";
      }
      console.log(params);
      eventList({params}).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          this.tableData = data.list;
          this.pageObj.total = Number(data.totalRow);
        }
      })
    },
    //查询
    queryUserInfo() {
      this.getUserInfo();
    },
    //新增
    addUserInfo() {
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'add';
      this.form = Object.assign({},formObj,{placeInvolvedList:[]});
    },
    //表单新增
    dialogSubmit(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFun();
        } else {
          return false;
        }
      });
    },
    submitFormFun(){
      let params = Object.assign({},this.form);
      // var API = this.dialogFormVisibleType == 'add'?eventSave:eventUpdate;
      var API = eventSave;
      this.loading = true
      API(params).then((res)=>{
        let { code, data, msg } = res.data;
        if(code == 200){
          this.dialogFormVisible = false;
          this.$message({
            type: 'success',
            message: '保存成功!'
          });
          this.queryUserInfo();
          this.loading = false
        }else{
          // this.dialogFormVisible = false;
          this.loading = false
        }
      })
    },

    //table操作
    //修改
    editTableFun(row){
      this.dialogFormVisible = true;
      this.dialogFormVisibleType = 'set';
      this.getCSList(row);
      // this.form = Object.assign({},row,{placeInvolvedList:row.placeInvolvedList || []});
    },

    //结束
    deleteTableFun(row){
      this.$confirm(`确定要${row.eventStatus=='0'?'结束':'重启'}【${row.eventName}】吗？${row.eventStatus=='0'?'结束后不可再编辑信息，流调将不能使用':''}`, '操作确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = Object.assign({},row,{eventStatus:row.eventStatus=='1'?'0':'1',endTime:row.eventStatus=='0'?subTime(new Date(),'dateTime'):''});
          eventUpdate(params).then((res)=>{
            let { code, data, msg } = res.data;
            if(code == 200){
              this.$message({
                type: 'success',
                message: '修改成功!'
              });
              this.queryUserInfo();
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消修改'
          });
        });
    },

    // 删除
    deleteTable(row){
      // console.log(row);
      this.$confirm("确定要删除改事件吗？", '操作确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
            id:row.id
          }
          eventDelete(params).then((res)=>{
            let { code, data, msg } = res.data;
            if(code == 200){
              this.$message({
                type: 'success',
                message: msg
              });
              this.queryUserInfo();
            }
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },

    //分页变化
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.getUserInfo();
    },
    handleCurrentChange(val) {
      this.pageObj.pageNumber = val;
      this.getUserInfo();
    },


  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/condition-filtrate.scss";

.content-page {
  margin-top: 10px;
  height: 40px;
  display: flex;
  justify-content: flex-end;
}

.el-link {
  margin-right: 10px;
}

.el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
</style>
